<template>
  <svg viewbox="0 0 200 200" width="200" height="200">
    <circle
      :stroke="bgcolor"
      fill="none"
      :stroke-width="stroke"
      :r="normalizedRadius"
      cx="100"
      cy="100"
      stroke-linecap="round"
    />
    <circle
      :stroke="fgcolor"
      fill="none"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset }"
      :stroke-width="stroke"
      :r="normalizedRadius"
      cx="100"
      cy="100"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    bgcolor: { type: String, default: "#f6f4f4" },
    fgcolor: { type: String, default: "var(--primary)" },
    value: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    stroke: { type: Number, default: 10 },
  },
  data() {
    const normalizedRadius = 100 - this.stroke / 2
    const circumference = normalizedRadius * 2 * Math.PI

    return {
      normalizedRadius,
      circumference,
    }
  },
  computed: {
    /** @returns {Number} */
    strokeDashoffset() {
      return (
        this.circumference -
        Math.min(this.value / this.max, 1) * this.circumference
      )
    },
  },
}
</script>

<style scoped>
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
