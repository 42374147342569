<template>
  <b-container class="my-2 my-sm-5" fluid="lg">
    <h1>{{ $t("myhealth.title") }}</h1>

    <b-row>
      <b-col>
        <b-overlay :show="reportLoading">
          <b-card>
            <b-form @submit.prevent="addActivity">
              <b-row cols="1" cols-md="2" align-v="center">
                <b-col>
                  <FormField
                    tagname="b-form-datepicker"
                    varname="report_dt"
                    label="myhealth.date"
                    v-model="report_dt"
                    :errors="errors"
                    :max="now"
                    :start-weekday="1"
                    hide-header
                    label-help=""
                    :locale="
                      $store.getters.language == 'se'
                        ? 'sv'
                        : $store.getters.language
                    "
                    required
                    v-bind="Object($t('datepicker'))"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      weekday: undefined,
                    }"
                  />
                </b-col>

                <b-col>
                  <FormField
                    tagname="b-form-select"
                    varname="activity_id"
                    label="activity"
                    v-model="activity_id"
                    :errors="errors"
                  >
                    <b-form-select-option
                      selected
                      disabled
                      value=""
                    ></b-form-select-option>
                    <b-form-select-option
                      v-for="activity in activities"
                      :value="activity.id"
                      :key="activity.id"
                    >
                      {{ activity.transl_name }}
                    </b-form-select-option>
                  </FormField>
                </b-col>

                <b-col>
                  <FormField
                    varname="activity_quantity"
                    :label="unit"
                    :translate-label="false"
                    v-model.number="activity_quantity"
                    :errors="errors"
                    min="1"
                    type="number"
                    required
                  />
                </b-col>

                <b-col>
                  <b-row class="align-items-center" style="row-gap: 1rem">
                    <b-col cols="12" class="cols-xxl-2">
                      <b-button block variant="primary" type="submit">
                        {{ $t("myhealth.submit") }}
                      </b-button>
                    </b-col>
                    <SyncStepsButton
                      wrapper-elem="b-col"
                      @stepsSynced="
                        () => {
                          handleActivitySaved()                          
                        }
                      "
                    />
                  </b-row>
                </b-col>
              </b-row>
              <b-form-invalid-feedback
                :state="error ? false : null"
                class="mb-3"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form>
          </b-card>
        </b-overlay>

        <b-tabs
          class="my-3"
          :justified="['xs', 'sm', 'md'].includes($store.state.breakpoint)"
          :nav-class="
            ['xs', 'sm', 'md'].includes($store.state.breakpoint) ? 'w-100' : ''
          "
        >
          <b-tab
            title-link-class="px-1 px-sm-4.5 h-100 d-flex align-items-center justify-content-center"
            :title="$t('myhealth.daily_summary')"
            class="py-2 py-sm-4 px-1 px-sm-4"
            active
          >
            <b-overlay :show="graphLoading">
              <small>{{ $t("myhealth.points_overview") }}</small>
              <br />
              <small class="text-muted">{{ $t("myhealth.pantoshow") }}</small>

              <PointChart
                :data="chartData"
                :unit="`${$t('points')}`"
                :goal="100"
                @scrollEnd="showOlder()"
              />
            </b-overlay>
          </b-tab>

          <b-tab
            title-link-class="px-1 px-sm-4.5 h-100 d-flex align-items-center justify-content-center"
            :title="$t('myhealth.my_activities')"
            class="overflow-hidden"
            lazy
          >
            <b-overlay :show="myActivitiesLoading">
              <b-table-simple
                striped
                small
                hover
                responsive
                class="mb-0 px-md-3"
              >
                <colgroup>
                  <col v-for="(_, i) in Array(columns.length)" :key="i" />
                  <col />
                </colgroup>

                <b-thead>
                  <b-th v-for="column in columns" :key="column">
                    {{ $t(column) }}
                  </b-th>
                  <b-th style="width: auto"></b-th>
                </b-thead>

                <b-tbody>                  
                  <b-tr
                    v-for="activity in myActivities"
                    :key="activity.source_hashkey"
                  >
                    <b-td>{{ activity.report_dt }}</b-td>
                    <b-td style="word-wrap: anywhere;">
                    <b-img
                      :src="activity.data_provider_logo_file_name ? `${activity.data_provider_logo_file_path}/${activity.data_provider_logo_file_name}` : ''"
                      fluid
                      class="mr-2"
                      style="max-height: 1.5em; width: auto; vertical-align: middle;"
                      v-if="activity.data_provider && activity.data_provider_logo_file_path"
                      :title="activity.data_provider"
                    />

                      {{ activity.transl_name }}
                    </b-td>
                    <b-td>
                      {{
                        activity.steps
                          ? separateThousand(activity.activity_quantity)
                          : null
                      }}
                    </b-td>
                    <b-td>
                      {{
                        activity.steps
                          ? null
                          : separateThousand(activity.activity_quantity)
                      }}
                    </b-td>
                    <b-td>
                      {{ activity.points }}
                    </b-td>
                    <b-td>
                      <b-icon-trash
                        :title="$t('myhealth.delete_activity')"
                        font-scale="1.1"
                        class="text-danger cursor-pointer"
                        @click="deleteActivity(activity.source_hashkey)"
                      />
                    </b-td>
                  </b-tr>

                  <b-tr v-if="next_page_url">
                    <b-td :colspan="columns.length + 1">
                      <b-button variant="primary" block @click="showOlder">
                        {{
                          `... ${$t(
                            "myhealth.earlier_activities"
                          )} ...`.toLocaleLowerCase()
                        }}
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>

                <b-tfoot>
                  <b-td
                    variant="primary"
                    class="font-weight-bold"
                    colspan="2"
                  >
                    {{ $t("total") }}:
                  </b-td>
                  <b-td variant="primary">
                    {{
                      `${separateThousand(totalSteps)} ${$t(
                        "steps"
                      )}`.toLocaleLowerCase()
                    }}
                  </b-td>
                  <b-td variant="primary">
                    {{
                      `${separateThousand(totalMinutes)} ${$t(
                        "minutes"
                      )}`.toLocaleLowerCase()
                    }}
                  </b-td>
                  <b-td variant="primary">
                    {{
                      `${separateThousand(myTotalPoints)} ${$t(
                        "points"
                      )}`.toLocaleLowerCase()
                    }}
                  </b-td>
                  <b-td variant="primary"></b-td>
                </b-tfoot>
              </b-table-simple>
            </b-overlay>
          </b-tab>
          <!-- Guided Workouts Tab -->
          <b-tab
            title-link-class="px-1 px-sm-4.5 h-100 d-flex align-items-center justify-content-center"
            :title="$t('myhealth.guided_workouts')"
            class="py-2 py-sm-4 px-1 px-sm-4"
            lazy
            @activated="fetchFilteredWorkouts"
          >
            <b-overlay :show="workoutsLoading" rounded opacity="0.8">
              <!-- Filters Section -->
            <div class="filter-container">
              <!-- Category Filter -->
              <b-form-group class="mb-3">
                <b-form-select
                  v-model="selectedCategory"
                  @change="fetchFilteredWorkouts"
                  size="md"
                  class="custom-select"
                >
                  <!-- Default Placeholder (acts as reset) -->
                  <b-form-select-option value="">
                    {{ $t('myhealth.select_category') }}
                  </b-form-select-option>

                  <!-- Dynamic Options -->
                  <b-form-select-option
                    v-for="category in workoutCategories"
                    :value="category.original"
                    :key="category.original"
                  >
                    {{ category.translated }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <!-- Difficulty Filter -->
              <b-form-group>
                <b-form-select
                  v-model="selectedDifficulty"
                  @change="fetchFilteredWorkouts"
                  size="md"
                  class="custom-select"
                >
                  <!-- Default Placeholder (acts as reset) -->
                  <b-form-select-option value="">
                    {{ $t('myhealth.select_difficulty') }}
                  </b-form-select-option>

                  <!-- Dynamic Options -->
                  <b-form-select-option
                    v-for="difficulty in difficulties"
                    :value="difficulty"
                    :key="difficulty"
                  >
                    {{ $t(`myhealth.difficulties.${difficulty}`) }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <!-- Optional "Clear Filters" Button -->
              <b-button
                v-if="selectedCategory || selectedDifficulty"
                variant="outline-primary"
                size="sm"
                class="mt-0 mb-4"
                @click="clearFilters"
              >
                {{ $t('myhealth.clear_filters') }}
              </b-button>
            </div>
            
                <!-- Slimmed Workout List -->
              <b-list-group>
                <b-list-group-item
                  v-for="workout in guidedWorkouts"
                  :key="workout.id"
                  class="d-flex align-items-center justify-content-between py-3"
                >
                  <!-- Thumbnail and Info -->
                  <div class="d-flex align-items-center">
                    <b-img
                      :src="workout.thumb_media_file ? workout.thumb_media_file.file_path + workout.thumb_media_file.file_name : ''"
                      alt="Workout Thumbnail"
                      width="100%"
                      height="70"
                      
                      rounded
                      class="mr-3"
                    ></b-img>
                    <div>
                        <h6 class="mb-1 font-weight-bold">
                          {{ workout.translations && workout.translations.length > 0 ? workout.translations[0].name : '' }}
                        </h6>
                      <small class="text-muted">
                        {{ workout.translations && workout.translations.length > 0 ? workout.translations[0].category : '' }} · 
                        {{ workout.translations && workout.translations.length > 0 ? workout.translations[0].difficulty : '' }} · 
                        {{ workout.duration }} min
                      </small>
                    </div>
                  </div>
                  <!-- Points and Action -->
                  <div class="d-flex align-items-center">
                    <span class="text-primary font-weight-bold mr-4 no-wrap">{{ workout.total_points || 0 }} {{ $t('points') }}</span>
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="openWorkoutModal(workout.id)"
                      class="d-flex align-items-center justify-content-center"
                    >
                      <b-icon-playFill class="icon mr-1" />
                      {{ $t("myhealth.join_workout") }}
                    </b-button>
                  </div>
                </b-list-group-item>

                <!-- No Workouts Found -->
                <div v-if="!workoutsLoading && guidedWorkouts.length === 0" class="text-center text-muted py-5">
                  {{ $t("myhealth.no_workouts_found") }}
                </div>
              </b-list-group>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </b-col>

      <b-col class="mt-3 mt-sm-0" cols="12" xl="auto">
        <b-overlay :show="statisticsLoading">
          <b-card no-body tag="aside">
            <template #header>
              <h2 class="mb-0">{{ $t("statistics") }}</h2>
            </template>
            <div class="position-relative mx-auto">
              <CircleProgress :value="myPoints" :max="totalPoints" />

              <div
                class="position-absolute d-flex flex-column justify-content-center align-items-center rounded-circle p-3 text-center font-weight-semibold"
                style="top: 50%; left: 50%; transform: translate(-50%, -50%);"
                :style="{
                  // 100% minus stroke minus margin
                  width: 'calc(100% - 20px - 25px)',
                  height: 'calc(100% - 20px - 25px)',
                  backgroundColor: '#f6f4f4',
                  fontSize: '1.25rem',
                }"
              >
                <div>{{ $t("points") }}</div>
                <div>
                  <span :class="myPoints < totalPoints && 'text-terciary'">
                    {{ myPoints }}
                  </span>
                  /
                  <span>{{ totalPoints }}</span>
                </div>
              </div>
            </div>

            <b-list-group flush>
              <b-list-group-item>
                <div
                  class="mb-3 d-flex justify-content-between align-items-baseline text-terciary font-weight-semibold"
                >
                  <div class="mr-3">{{ $t("myhealth.average_steps") }}:</div>
                  <div>{{ stepAvg }}</div>
                </div>
                <b-progress :value="stepAvg" :max="10000" />
              </b-list-group-item>
              <b-list-group-item>
                <div
                  class="mb-3 d-flex justify-content-between align-items-baseline text-terciary font-weight-semibold"
                >
                  <div class="mr-3">{{ $t("myhealth.highest_steps") }}:</div>
                  <div>{{ pb }}</div>
                </div>
                <b-progress :value="pb" :max="10000" />
              </b-list-group-item>
              <b-list-group-item>
                <div
                  class="mb-3 d-flex justify-content-between align-items-baseline text-terciary font-weight-semibold"
                >
                  <div class="mr-3 baseline text-terciary">{{ $t("myhealth.points_average") }}:</div>
                  <div>{{ pointsAvg }}</div>
                </div>
                <b-progress :value="pointsAvg" :max="100" />
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-overlay>

        <b-card no-body tag="aside" class="mt-3">
          <template #header>
            <h2 class="mb-0">{{ $t("myhealth.point_types-title") }}</h2>
          </template>

          <b-list-group flush>
            <b-list-group-item
              v-for="type in [
                { name: 'activitypoints', color: 'var(--primary)' },
                { name: 'missionpoints', color: 'var(--success)' },
                { name: 'quizpoints', color: 'var(--accent)' },
              ]"
              :key="type.name"
            >
              <div class="d-flex align-items-center">
                <div
                  class="mr-2"
                  style="width: 1rem; height: 1rem; border-radius: 5px;"
                  :style="{ background: type.color }"
                />
                <h6 class="mb-0">{{ $t(type.name) }}</h6>
              </div>
              <small class="text-muted m-0">
                {{ $t(`myhealth.point_types.${type.name}`) }}
              </small>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>    

    <!-- Workout Modal -->
    <WorkoutModal
      :workoutId="selectedWorkoutId"
      v-if="showWorkoutModal"
      @close="closeWorkoutModal"
      @activity-saved="handleActivitySaved"
    />

      
  </b-container>  
</template>

<script>
import { BIconTrash, BIconPlayFill } from "bootstrap-vue"
import axios from "axios"
import FormField from "@/components/FormField.vue"
import PointChart from "@/components/PointChart.vue"
import CircleProgress from "@/components/CircleProgress.vue"
import SyncStepsButton from "@/components/SyncStepsButton.vue"
import WorkoutModal from "@/components/WorkoutModal.vue";

export default {
  components: {
    BIconTrash,
    BIconPlayFill,
    FormField,
    PointChart,
    CircleProgress,
    SyncStepsButton,
    WorkoutModal,
  },

  data() {
    return {
      now: "",
      reportLoading: false,
      myActivitiesLoading: false,
      statisticsLoading: false,
      graphLoading: false,
      chartSymmaryData: [],
      error: "",
      errors: {},
      report_dt: "",
      activity_id: 2,
      /** @type {Array<any>} */
      activities: [],
      activity_quantity: null,
      columns: ["date", "activity", "steps", "minutes", "points"],
      /** @type {Array<any>} */
      myActivities: [],
      next_page_url: null,
      syncStepsLoading: false,

      stepAvg: null,
      pb: null,
      calculatedStartDt: null,
      /** @type {Number | undefined} */
      totalPoints: null,
      /** @type {Number | undefined} */
      myPoints: null,
      pointsAvg: null,
      workoutCategories: [], // Categories fetched from the API
      difficulties: ["Beginner", "Intermediate", "Advanced"], // Static English difficulties
      selectedCategory: "", // Selected category filter
      selectedDifficulty: "", // Selected difficulty filter
      guidedWorkouts: [], // Workouts to display
      workoutsLoading: false,
      selectedWorkoutId: null,
      showWorkoutModal: false,      
    }
  },
  computed: {
    /**
     * Current activity
     * @return {Object} */
    currentActivity() {
      return this.activities.find(e => e.id == this.activity_id)
    },
    /** @return {String} */
    unit() {
      if (this.currentActivity) {
        const unitname = this.currentActivity.activity_unit_name.toLowerCase()
        return `${this.$t("amount")} ${this.$t(unitname).toLowerCase()}`
      }
      return this.$t("quantity")
    },
    /** @return {Number} */
    totalSteps() {
      const steps = this.myActivities.map(e => {
        if (e.steps) return e.activity_quantity
        return 0
      })
      if (steps.length) {
        return steps.reduce((acc, val) => acc + val)
      }
      return 0
    },
    /** @return {Number} */
    totalMinutes() {
      const minutes = this.myActivities.map(e => {
        if (!e.steps) return e.activity_quantity
        return 0
      })
      if (minutes.length) {
        return minutes.reduce((acc, val) => acc + val)
      }
      return 0
    },
    /** @return {Number} */
    myTotalPoints() {
      if (this.myActivities.length) {
        return this.myActivities.reduce(
          (acc, val) => acc + Number(val.points),
          0
        )
      }
      return 0
    },
    /** @return {Array<any>} */
    chartData() {
      if (!this.chartSymmaryData.length) {
        return []
      }
      // Filter old dates and sort ascending
      const sortedActivities = this.chartSymmaryData
        .slice()
        .sort((a, b) => new Date(a.report_dt) - new Date(b.report_dt)) // Sort by date
      // Get date one year from last date
      let oneYear = new Date(
        sortedActivities[sortedActivities.length - 1].report_dt
      )
      oneYear.setFullYear(oneYear.getFullYear() - 1)
      // Filter dates that are more than a year ago
      const filteredActivities = sortedActivities.filter(
        e => new Date(e.report_dt) > oneYear
      )
      let summed = {}
      // Create object to prevent duplicates
      filteredActivities.map(e => {
        // Populate object for each date
        if (e.report_dt in summed) {
          summed[e.report_dt].points_activity += parseInt(e.points_activity, 10)
          summed[e.report_dt].points_bonus += parseInt(e.points_bonus, 10)
          summed[e.report_dt].points_quiz += parseInt(e.points_quiz, 10)
        } else {
          summed[e.report_dt] = {
            date: new Date(e.report_dt),
            // name: e.source_hashkey,
            points_activity: parseInt(e.points_activity, 10) || 0,
            points_bonus: parseInt(e.points_bonus, 10) || 0,
            points_quiz: parseInt(e.points_quiz, 10) || 0,
          }
        }
      })
      // Change object back to array
      let pointdata = Object.values(summed)
      // Interpolate missing dates and add zero-value for those
      if (pointdata.length >= 2) {
        const one_day = 1000 * 60 * 60 * 24
        for (let i = 0; i < pointdata.length; i++) {
          const date = pointdata[i].date
          const nextDate = pointdata[i + 1]?.date || new Date()
          if (nextDate) {
            if (nextDate - date > one_day) {
              let dayAfter = new Date(pointdata[i].date.getTime())
              dayAfter.setDate(dayAfter.getDate() + 1)
              pointdata.splice(i + 1, 0, {
                date: dayAfter,
                // name: "blank" + i,
                points_activity: 0,
                points_bonus: 0,
                points_quiz: 0,
              })
            }
          }
        }
      }
      return pointdata
    },
  },

  created() {
    let d = new Date()
    let year = d.getFullYear().toString()
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate().toString()
    this.now = `${year}-${month.length < 2 ? "0" + month : month}-${
      day.length < 2 ? "0" + day : day
    }`
    // Date selector defaults to today
    this.report_dt = this.now

    this.getActivities()
    this.getMyActivities()
    this.getChartSummary()
    this.getStatistics()
    this.getWorkouts()
    this.fetchWorkoutCategories()
    this.fetchFilteredWorkouts()
  },
  methods: {
    separateThousand(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    async getActivities() {      
      this.reportLoading = true

      try {
        const response = await axios.get("/activitylist")
        this.activities = response.data?.data ?? []
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.reportLoading = false
      }
    },
    async getMyActivities(next_url) {      
      this.myActivitiesLoading = true
      let url = next_url ? new URL(next_url) : null

      try {
        // url?.search extracts query params and applies it to current url
        const response = await axios.get(
          "/useractivity/my" + (url?.search || "")
        )
        const { data, links } = response.data
        if (url?.search) {
          // If fetching next page --> append to current activities
          this.myActivities = this.myActivities.concat(data)
        } else {
          this.myActivities = data
        }
        this.next_page_url = links.next_page_url
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.myActivitiesLoading = false
        // Sort descending
        this.myActivities.sort(
          (a, b) => new Date(b.report_dt) - new Date(a.report_dt)
        )
      }
    },
    async getChartSummary() {
      this.graphLoading = true
      try {
        const response = await axios.get("/useractivity/summary")
        // TODO: pagination
        const { data /* links */ } = response.data
        this.chartSymmaryData = data
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.graphLoading = false
        // Sort descending
        this.chartSymmaryData.sort(
          (a, b) => new Date(b.report_dt) - new Date(a.report_dt)
        )
      }
    },
    async getStatistics() {
      this.statisticsLoading = true

      try {
        const response = await axios.get("/useractivity/myhealthstat")
        // Extract variables from return data
        const {
          stepAvg,
          pb,
          calculatedStartDt,
          totalPoints,
          myPoints,
          pointsAvg,
        } = response.data.data
        Object.assign(this, {
          stepAvg,
          pb,
          calculatedStartDt,
          totalPoints,
          myPoints,
          pointsAvg,
        })
        this.myPoints = parseFloat(this.myPoints)
        this.totalPoints = parseFloat(this.totalPoints)
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.statisticsLoading = false
      }
    },

    async showOlder() {
      if (this.next_page_url) {
        this.getMyActivities(this.next_page_url)
      }
    },

    async addActivity() {
      this.reportLoading = true
      this.error = ""
      this.errors = {}

      try {
        // Get values to be posted
        const { report_dt, activity_id, activity_quantity } = this
        const response = await axios.post("/useractivity", {
          report_dt,
          activity_id,
          activity_quantity,
        })
        // Show a card with latest points
        let latestPoints = response.data?.points ?? 0
        // Show global alert
        if (latestPoints)
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: `${this.$t(
              "myhealth.report-success"
            )} ${latestPoints} ${this.$t("points").toLocaleLowerCase()}`,
            dismissSecs: 10,
          })
        // Clear form
        this.activity_quantity = null
      } catch ({ name, message, response }) {
        if (response?.data) {
          const { errors, message } = response.data
          this.error = message
          this.errors = errors
        } else {
          this.error = `${name}: ${message}`
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message ?? `${name}: ${message}`,
          })
        }
      } finally {
        this.reportLoading = false
        this.handleActivitySaved()
      }
    },

    async deleteActivity(source_hashkey) {
      // Show confirmation dialog
      const confirm = await this.$bvModal.msgBoxConfirm(
        this.$t("myhealth.delete_activity") + "?",
        {
          okTitle: this.$t("delete"),
          okVariant: "primary",
          cancelTitle: this.$t("cancel"),
          cancelVariant: "outline-primary"
        }
      )
      if (!confirm) return

      this.myActivitiesLoading = true

      try {
        await axios.delete("/useractivity", {
          data: { source_hashkey },
        })
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "primary",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.myActivitiesLoading = false
        this.getMyActivities()
        this.getChartSummary()
        this.getStatistics()
      }
    },
    async getWorkouts() {      
      // If you prefer always refreshing, remove this "already loaded" check
      if (this.guidedWorkouts.length > 0) return

      this.workoutsLoading = true
      try {
        // Adjust as needed if your API path/response structure differs
        const response = await axios.get("/workouts")
        // response.data will be { data: [ {id, name, ...}, ... ] }
        this.guidedWorkouts = response.data?.data || []
      } catch (error) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: error.response?.data?.error_message || error.message,
        })
      } finally {
        this.workoutsLoading = false
      }
    },
    joinWorkout(workout) {
      // Example: a simple message or real logic
      this.$store.dispatch("addToastMessage", {
        type: "success",
        text: `Joining workout: ${workout.name}`,
      })
      // Optionally, open a modal or navigate to a detail page
    },
    async fetchWorkoutCategories() {
      try {
        const response = await axios.get("/workout/categories");
        this.workoutCategories = response.data?.data || [];
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    },
    async fetchFilteredWorkouts() {
      this.workoutsLoading = true;

      try {
        const params = {};
        if (this.selectedCategory) params.category = this.selectedCategory;
        if (this.selectedDifficulty) params.difficulty = this.selectedDifficulty;

        const response = await axios.get("/workouts", { params });
        this.guidedWorkouts = response.data?.data || [];
      } catch (error) {
        console.error("Failed to fetch workouts:", error);
      } finally {
        this.workoutsLoading = false;
      }
    },
    clearFilters() {
      this.selectedCategory = "";
      this.selectedDifficulty = "";
      this.fetchFilteredWorkouts();
    },    
    openWorkoutModal(workoutId) {
      this.selectedWorkoutId = workoutId;
      this.showWorkoutModal = true;
    },
    closeWorkoutModal() {
      this.selectedWorkoutId = null;
      this.showWorkoutModal = false;
    },   
    handleActivitySaved() {
      // Refresh the activity list, chart, and statistics
      this.getMyActivities();
      this.getChartSummary();
      this.getStatistics();      
    },     
  },
}
</script>

<style scoped>
@media (min-width: 1400px) {
  .cols-xxl-2:not(:only-child) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.no-wrap {
  white-space: nowrap;
  flex-shrink: 0;
}


</style>
