<template>
  <b-modal
    id="play-workout-modal"
    size="lg"
    @hidden="$emit('close')"
    hide-footer
    :visible="true"
  >
    <!-- Video Section -->
    <b-embed
      type="iframe"
      aspect="16by9"
      :src="`${workout.live_media.file_path}${workout.live_media.file_name}`"
      allowfullscreen
      controls
      preload="metadata"
    >
      Not supported
    </b-embed>

    <!-- Workout Details -->
    <div class="mb-3 pt-5">
      <!-- Workout Title -->
      <h2 class="font-weight-bold text-center mb-2">
        {{ workout.translations[0].name }}
      </h2>

      <!-- Category, Difficulty, Duration & Points (Meta Info) -->
      <div class="d-flex justify-content-center align-items-center meta-info">
        <span class="meta-item">
          <b-icon-tags class="icon" /> {{ workout.translations[0].category }}
        </span>
        <span class="separator">|</span>
        <span class="meta-item">
          <b-icon-lightning-fill class="icon" /> {{ workout.translations[0].difficulty }}
        </span>
        <span class="separator">|</span>
        <span class="meta-item">
          <b-icon-clock-fill class="icon" /> {{ workout.duration }} min
        </span>
        <span class="separator">|</span>
        <span class="meta-item">
          <b-icon-award-fill class="icon" /> {{ workout.total_points }} {{ $t("points") }}
        </span>
      </div>

      <!-- Expandable Description Section -->
      <div class="text-center mt-3">
        <p class="description-text">{{ isExpanded ? workout.translations[0].description : shortDescription }}</p>
        <b-button variant="link" class="toggle-description" @click="isExpanded = !isExpanded">
          {{ isExpanded ? $t("myhealth.show_less") : $t("myhealth.read_more") }}
        </b-button>
      </div>

      <!-- Action Buttons -->
      <b-button
        :variant="checkInCompleted ? 'secondary' : 'primary'"
        block
        @click="handleCheckIn"
        class="mt-4"
      >
        {{ checkInCompleted ? $t("myhealth.workout_completed") : $t("myhealth.check_in") }}
      </b-button>

      <b-button
        v-if="checkInCompleted && !isDeleted"
        variant="outline-primary"
        block
        @click="confirmDelete"
        class="mb-4"
      >
        <b-icon-trash class="icon mr-1" /> {{ $t("myhealth.delete_workout") }}
      </b-button>
    </div>    
    <!-- Exercises Section -->
    <h4 class="font-weight-bold mb-3 mt-5 pl-3 pr-3">{{ $t('myhealth.exercises') }}</h4>
    <b-list-group class="pl-3 pr-3">
      <b-list-group-item
        v-for="exercise in workout.exercises"
        :key="exercise.id"
        class="d-flex align-items-center"
      >
        <!-- Show image if available, otherwise show icon -->
        <img
          v-if="exercise.thumbnail && exercise.thumbnail.file_path && exercise.thumbnail.file_name"
          :src="`${exercise.thumbnail.file_path}${exercise.thumbnail.file_name}`"
          class="exercise-thumbnail mr-3"
          alt="Exercise thumbnail"
        />
        <b-icon-display v-else class="icon mr-1" style="color:#4caf50" />

        <div>
          <p class="mb-1">{{ exercise.exercise_type }}</p>
          <small class="text-muted">
            {{ computedExerciseDetails(exercise) }}
          </small>
        </div>
      </b-list-group-item>
    </b-list-group>

  </b-modal>
</template>


<script>
import { BIconClockFill, BIconAwardFill, BIconDisplay, BIconLightningFill, BIconTags } from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BIconClockFill,
    BIconAwardFill,
    BIconDisplay,
    BIconLightningFill,
    BIconTags
  },
  props: {
    workoutId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      workout: null,
      checkInCompleted: false,
      sourceHashkey: null,
      isDeleted: false,
      isExpanded: false
    };
  },
  computed: {
    shortDescription() {
      if (!this.workout || !this.workout.translations || !this.workout.translations[0].description) {
        return "";
      }
      return this.isExpanded ? this.workout.translations[0].description : this.workout.translations[0].description.slice(0, 200) + "...";
    },
    computedExerciseDetails() {
      return (exercise) => {
        const durationOrReps = exercise.duration_seconds
          ? `${exercise.duration_seconds}s`
          : `${exercise.reps} ${this.$t("myhealth.reps")}`;

        const paceLevelLocalized = this.$t(`myhealth.${exercise.pace_level}`);
        return `${this.$t("myhealth.duration")}: ${durationOrReps} · ${this.$t("myhealth.pace")}: ${paceLevelLocalized}`;
      };
    }    
  },
  async created() {
    await this.fetchWorkout();
  },
  methods: {
    async fetchWorkout() {
      try {
        const response = await axios.get(`/workouts/${this.workoutId}`);
        this.workout = response.data.data[0];
      } catch (error) {
        console.error("Failed to fetch workout:", error);
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: error.response?.data?.error_message || error.message,
        });
        this.$emit("close");
      }
    },
    async handleCheckIn() {
      if (this.checkInCompleted) return;

      try {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        const reportDate = `${year}-${month}-${day}`;

        const data = {
          activity_id: this.workout.activity_id,
          activity_quantity: this.workout.duration,
          report_dt: reportDate,
        };
        const response = await axios.post("/useractivity/workout", data);

        this.$emit("activity-saved");
        this.checkInCompleted = true;
        this.isDeleted = false;

        this.$store.dispatch("addToastMessage", {
          type: "success",
          text: this.$t("myhealth.checked_in"),
        });

        this.sourceHashkey = response.data.source_hashkey;
      } catch (error) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: error.response?.data?.error_message || error.message,
        });
      }
    },
    async confirmDelete() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        this.$t("myhealth.delete_activity") + "?",
        {
          okTitle: this.$t("delete"),
          okVariant: "primary",
          cancelTitle: this.$t("cancel"),
          cancelVariant: "outline-primary"
        }
      );
      if (!confirm) return;

      try {
        await axios.delete("/useractivity", {
          data: { source_hashkey: this.sourceHashkey },
        });

        this.$emit("activity-saved");
        this.checkInCompleted = false;
        this.isDeleted = true;

        this.$store.dispatch("addToastMessage", {
          type: "success",
          text: this.$t("myhealth.workout_deleted"),
        });
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "primary",
          text: response.data.error_message ?? `${name}: ${message}`,
        });
      }
    }
  }
};
</script>


<style scoped>
  .badge-with-padding {
    padding: 0.5rem 1rem; /* Adjust padding for better spacing */
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* Space between icon and text */
    font-size: 0.875rem; /* Adjust font size if needed */
  }

  .badge-with-padding .icon {
    font-size: 1.25rem; /* Adjust icon size if necessary */
    vertical-align: middle;
  }

.meta-info {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  color: #545454;
  padding-top:10px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.separator {
  color: #aaa;
}

.description-text {
  padding:10px 20px 10px 20px;
  font-size: 16px;
  color: #545454;
  line-height: 1.5;
}

.toggle-description {
  font-size: 15px;
  color: #E63375;
  text-decoration: underline;
}
.toggle-description:focus, 
.toggle-description:active {
  outline: none !important;
  box-shadow: none !important;
}

.exercise-thumbnail {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
}

</style>
