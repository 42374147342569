<template>
  <component
    :is="wrapperElem || 'div'"
    v-if="hasDataProvider && dataProviderIsValid"
  >
    <b-overlay :show="loading">
      <b-button
        block
        variant="outline-primary"
        type="button"
        @click="syncSteps"
        :disabled="loading"
      >
        {{ $t("myhealth.sync_steps") }}
      </b-button>
    </b-overlay>
  </component>
</template>

<script>
import axios from "axios"

export default {
  props: {
    wrapperElem: {
      default: null
    }
  },
  data() {
    return {
      loading: false,
      hasDataProvider: false,
      dataProviderIsValid: false,  // Added this to check if the data provider is valid
    }
  },
  created() {
    this.checkDataProvider()
  },
  methods: {
    async syncSteps() {
      this.loading = true
      try {
        await axios.get("/syncsteps")
        this.$store.dispatch("addToastMessage", {
          type: "success",
          text: this.$t("myhealth.steps_synced"),
        })
        this.$emit("stepsSynced")
      } catch ({ name, message }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: `${name}: ${message}`,
        })
      } finally {
        this.loading = false
      }
    },
    async checkDataProvider() {
      this.loading = true
      try {
        const response = await axios.get("/hasdataprovider")
        const hasProvider = response.data?.has_provider
        const dataProvider = response.data?.data_provider

        this.hasDataProvider = hasProvider
        // Check if the data provider is not "APPLEHEALTH", "HEALTHCONNECT", "GARMIN" (hide sync button)
        this.dataProviderIsValid = hasProvider && !["APPLEHEALTH", "HEALTHCONNECT", "GARMIN"].includes(dataProvider)
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
